import React from 'react';
import { Box, Img, Heading, SimpleGrid } from '@chakra-ui/react';
import CountUp from 'react-countup';

interface IFeatureMapProps {}

const FeatureMap: React.FunctionComponent<IFeatureMapProps> = () => (
  <Box
    as="section"
    maxW="1440px"
    w="100%"
    position="relative"
    mx="auto"
    mt={{ xs: '20px', md: '0' }}
    zIndex="-10"
    height="100%"
    mb={{ xs: '80px', sm: '80px', lg: '100px' }}
    px={{ xs: '20px', md: '0px' }}
  >
    <Img
      display={{ xs: 'none', md: 'block' }}
      alt="world map"
      objectFit="cover"
      src="/png/map.png"
      flexShrink="0"
      zIndex="-10"
      position="absolute"
      top={{ md: '80px', lg: '20px', xl: '180px' }}
      w={{ md: '250%', lg: '100%' }}
      height="100%"
    />
    <Box position="relative">
      <Box
        pt={{ xs: '40px', md: '50px', lg: '50px' }}
        pb={{ xs: '100px', sm: '80px', md: '100px', lg: '88px' }}
        zIndex="10"
      >
        <Heading
          as="h2"
          variant="calendarTitle"
          mb="5rem"
          color="text.textBlue"
          textAlign="center"
        >
          Indian PE/VC Industry
        </Heading>
        <SimpleGrid
          columns={{ xs: 1, md: 3 }}
          columnGap={{ md: '30px', lg: '0px' }}
          rowGap="25px"
          justifyItems={{ xs: 'flex-start', md: 'center' }}
          pl={{ xs: '40px', sm: '60px', md: '0px' }}
        >
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              $<CountUp end={327} start={0} duration={10} /> Bn+
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
            >
              Invested since 2015
            </Heading>
          </Box>
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              <CountUp end={4200} start={0} duration={10} />+
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
            >
              Companies funded since 2015
            </Heading>
          </Box>
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              $
              <CountUp end={129} start={0} duration={10} /> Bn+
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
            >
              Exits Recorded Since 2015
            </Heading>
          </Box>
        </SimpleGrid>
      </Box>

      <Box>
        <Heading
          as="h2"
          variant="calendarTitle"
          color="text.textBlue"
          textAlign="center"
        >
          Total SEBI registered AIFs and IVCA representation
        </Heading>
        <Heading
          as="h3"
          variant="featureTitle"
          color="primary.500"
          textTransform="capitalize"
          fontSize="14px"
          textAlign="center"
          mb="5rem"
        >
          (As of 15th June 2024)
        </Heading>
        <SimpleGrid
          columns={{ xs: 1, md: 4 }}
          columnGap={{ md: '30px', lg: '0px' }}
          rowGap="25px"
          justifyItems={{ xs: 'flex-start', md: 'center' }}
          pl={{ xs: '40px', sm: '60px', md: '0px' }}
        >
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              <CountUp end={623} start={0} duration={10} />
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
            >
              IVCA Member’s AIFs
            </Heading>
          </Box>
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              <CountUp end={1345} start={0} duration={10} />+
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
            >
              Total SEBI Registered AIFs
            </Heading>
          </Box>
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              <CountUp end={46} start={0} duration={10} />%
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
              maxW="255px"
              width="100%"
            >
              AIFs Represented by IVCA
            </Heading>
          </Box>
          <Box>
            <Heading
              as="h2"
              variant="largeInfo"
              lineHeight={{ xs: '75px', sm: '91px' }}
            >
              <CountUp end={74} start={0} duration={10} />%
            </Heading>
            <Heading
              as="h3"
              variant="featureTitle"
              color="primary.500"
              textTransform="capitalize"
              maxW="285px"
              width="100%"
            >
              IVCA Member Fund Managers have AIFs registered
            </Heading>
          </Box>
        </SimpleGrid>
      </Box>
    </Box>
  </Box>
);

export default FeatureMap;
