import React from 'react';
import { Box, Img, Flex, Heading, Text } from '@chakra-ui/react';
import Image from 'next/image';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';

interface IHomeTestimonialProps {}

const persons = [
  {
    id: 1,
    img: '/jpg/testimonialPerson.jpg',
    name: 'Narayan Murthy',
    role: 'Founder, Infosys',
    text: "The only instrument to create employment is entreprenuership & that can't be converted into wealth & jobs without the support of venture capital. Therfore, what IVCA is doing is truly an extraordinary initiative.",
  },
  {
    id: 2,
    img: '/png/testimonial2.png',
    name: 'Gautam Mehra',
    role: 'Partner & India Tax Leader, PwC India',
    text: 'IVCA as an industry body has done very well to get folks together and to educate policymakers & various stakeholders about what this industry really brings to the table.',
  },
  {
    id: 3,
    img: '/png/testimonial3.png',
    name: 'Anjali Bansal',
    role: 'Founder, Avaana Capital',
    text: 'I give enormous respect and due recognition to the IVCA leadership for strides made in terms of engagement with our regulatory ecosystem & creating responsibility and organization around our industry.',
  },
];

const settings = {
  dots: true,
  infinite: true,
  autoplay: true,
  lazyload: true,
  autoplaySpeed: 4000,
  arrows: false,
  adaptiveHeight: true,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 2,
  centerPadding: '60px',

  // eslint-disable-next-line react/no-unstable-nested-components
  customPaging: () => (
    <Box
      width="8px"
      height="8px"
      backgroundColor="text.lightDarkGray"
      borderRadius="50%"
    />
  ),
  dotsClass: 'slick-dots slick-homeTesti',
  responsive: [
    {
      breakpoint: 1424,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 3,
      },
    },
    {
      breakpoint: 1400,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1380,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1200,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 1000,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 980,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 580,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

const HomeDemoTestimonial: React.FunctionComponent<
  IHomeTestimonialProps
> = () => (
  <Box bgColor="bg.lightGrey" pt="50px" as="section">
    <Heading
      as="h2"
      variant="calendarTitle"
      color="text.darkHead"
      mb="10px"
      textAlign="center"
    >
      Testimonials
    </Heading>
    <Flex pb="142px" justify="space-between" pt="42px">
      <Img
        src="/png/testimonialDots.png"
        alt="testimonial background design image"
        h="191px"
        position="absolute"
        left="0"
        display={{ xs: 'none', xl: 'block' }}
      />
      <Box
        w={{ xs: '80%', md: '70%', lg: '80%', xl: '90%' }}
        zIndex="2"
        position="relative"
        mx="auto"
      >
        <Slider {...settings}>
          {persons.map((person) => (
            <Box
              key={person.id}
              width="85% !important"
              position="relative"
              left="50%"
              transform="translateX(-50%)"
              px={{
                xs: '12px',
                md: '18px',
              }}
              mx="auto"
              pt={{
                xs: '12px',
                md: '18px',
              }}
              pb={{
                xs: '12px',
                md: '20px',
              }}
              h="260px"
              bg="white"
            >
              <Flex
                // h="100%"
                columnGap={{ xs: '8px', md: '10px' }}
                mb={{ xs: '10', md: '16px' }}
                alignItems="center"
              >
                <Box borderRadius="50%" boxSize="65px" overflow="hidden">
                  <Image
                    src={person.img}
                    alt="person"
                    width={65}
                    height={65}
                    style={{ objectPosition: 'center' }}
                  />
                </Box>
                <Box>
                  <Heading
                    fontSize="1.125rem"
                    variant="milestone"
                    color="text.lightPurple"
                  >
                    {person.name}
                  </Heading>
                  <Text
                    fontSize="0.875rem"
                    variant="featuretext"
                    color="text.textGray"
                  >
                    {person.role}
                  </Text>
                </Box>
              </Flex>
              <Text variant="calendarDesc" color="text.mediumBlack">
                {person.text}
              </Text>
            </Box>
          ))}
        </Slider>
      </Box>
      <Img
        src="/png/testimonialDots.png"
        alt="testimonial background design image"
        position="absolute"
        right="0"
        h="191px"
        display={{ xs: 'none', xl: 'block' }}
      />
    </Flex>
  </Box>
);

export default HomeDemoTestimonial;
