import React from 'react';
import { Box, Flex, Heading, Text, Button, Link } from '@chakra-ui/react';
// import Link from 'next/link';
import Image from 'next/image';
import SectionContainer from '@/components/common/SectionContainer';

// banner size
interface IBannerSizeProps {
  xs?: string;
  md?: string;
  lg: string;
}

export interface ISlideProps {
  index: number;
  title?: string | JSX.Element;
  desc?: string | JSX.Element;
  banner: IBannerSizeProps;
  showBtn: boolean;
  buttons: { text: string; url?: string; external?: boolean };
  extra?: boolean;
  topComp?: JSX.Element;
  contactInfo?: JSX.Element;
}

const Slide: React.FunctionComponent<ISlideProps> = ({
  title,
  desc,
  banner,
  showBtn,
  buttons,
  extra,
  topComp = null,
  contactInfo = null,
  index,
}) => (
  <Flex
    flexDirection="column"
    align="center"
    w="100%"
    overflow="hidden"
    h="771px"
    maxH="75vh"
    pos="relative"
    className="slide-container"
  >
    <Image
      src={banner.lg}
      alt="hero-slider-img"
      className="home-hero-img"
      style={{
        objectFit: 'cover',
        objectPosition: 'center',
      }}
      fill
      priority={index === 0}
    />
    <Box
      pos="absolute"
      zIndex="0"
      top="0"
      left="0"
      right="0"
      bg="linear-gradient(91.71deg, #0A042D 41.83%, rgba(86, 182, 165, 0.46) 75.2%)"
      height="100%"
      width="100%"
      opacity="0.7"
    />

    <SectionContainer
      pl={{ xs: '30px', sm: '40px', md: '55px', lg: '80px', xl: '112px' }}
      height="100%"
      pb={{ xs: '120px', md: '60px' }}
      display="flex"
      flexDirection="column"
      justifyContent="center"
      position="relative"
    >
      <Flex
        columnGap={{ md: '30px', lg: '50px', xl: '113px' }}
        justify="space-between"
        flexDir={{ xs: 'column', lg: 'row' }}
      >
        <Flex flexDir="column">
          {topComp}
          {typeof title === 'string' ? (
            <Heading
              as="h1"
              variant="hero"
              w={{ xs: '100%', md: '100%', lg: '70%' }}
              fontSize={{ xs: '3rem', sm: '45px', md: '60px', lg: '74px' }}
              mb={{ xs: '30px', sm: '0px' }}
              noOfLines={{ xs: 4, sm: 3 }}
            >
              {title}
            </Heading>
          ) : (
            title
          )}
          {desc &&
            (typeof desc === 'string' ? (
              <>
                <Text
                  w={{
                    xs: '100%',
                    lg: '70%',
                  }}
                  variant="heroDesc"
                  mb={{ xs: '12px', md: '20px' }}
                  mt="15px"
                >
                  {desc}
                </Text>{' '}
              </>
            ) : (
              desc
            ))}
          {extra && (
            <Flex
              mt="16px"
              display={{ xs: 'none', sm: 'flex' }}
              columnGap={{ md: '15px', lg: '60px', xl: '90px' }}
              rowGap="16px"
              w="100%"
              mb="16px"
              flexDirection={{ xs: 'column', lg: 'row' }}
            >
              <Box mb={{ xs: '4px', md: '0' }}>
                <Heading
                  as="h3"
                  fontWeight="800"
                  fontSize="44px"
                  lineHeight="48px"
                  color="white"
                  mb="4px"
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  70%
                </Heading>
                <Text
                  variant="heroDesc"
                  fontSize={{ xs: '0.875rem', md: '1rem' }}
                  noOfLines={{ md: 2, lg: 3 }}
                >
                  <Text
                    color="inherit"
                    as="span"
                    fontSize={{ xs: '1.125rem', md: '1.65rem' }}
                    fontWeight="semibold"
                    display={{ xs: 'inline', lg: 'none' }}
                  >
                    70%{' '}
                  </Text>
                  of surveyed LPs say their organizations’ investment policies
                  include an ESG approach.
                </Text>
              </Box>
              <Box mb={{ xs: '4px', md: '0' }}>
                <Heading
                  as="h3"
                  fontWeight="800"
                  fontSize="44px"
                  lineHeight="48px"
                  color="white"
                  mb="4px"
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  85%
                </Heading>
                <Text
                  variant="heroDesc"
                  fontSize={{ xs: '0.875rem', md: '1rem' }}
                  noOfLines={{ md: 2, lg: 3 }}
                >
                  <Text
                    color="inherit"
                    as="span"
                    fontSize={{ xs: '1.125rem', md: '1.65rem' }}
                    fontWeight="semibold"
                    display={{ xs: 'inline', lg: 'none' }}
                  >
                    85%{' '}
                  </Text>
                  of those LPs have ab ESG investment policy fully (52%) or
                  partially(33%) implemented in private equity (PE).
                </Text>
              </Box>
              <Box>
                <Heading
                  as="h3"
                  fontWeight="800"
                  fontSize="44px"
                  lineHeight="48px"
                  color="white"
                  mb="4px"
                  display={{ xs: 'none', lg: 'flex' }}
                >
                  76%
                </Heading>
                <Text
                  variant="heroDesc"
                  fontSize={{ xs: '0.875rem', md: '1rem' }}
                  noOfLines={{ md: 2, lg: 3 }}
                >
                  <Text
                    color="inherit"
                    as="span"
                    fontSize={{ xs: '1.125rem', md: '1.65rem' }}
                    fontWeight="semibold"
                    display={{ xs: 'inline', lg: 'none' }}
                  >
                    76%{' '}
                  </Text>
                  of PE assets under management affected by surveyed LPs with an
                  ESG investment policy implemented in PE.
                </Text>
              </Box>
            </Flex>
          )}
          {contactInfo}
          {showBtn && (
            <Link
              href={buttons.url || '#'}
              aria-label={typeof title === 'string' ? title : buttons.text}
              target={buttons.external ? '_blank' : '_self'}
            >
              <Button
                variant="heroBtn"
                aria-label={typeof title === 'string' ? title : buttons.text}
                w={{ xs: '130px', md: '157px' }}
                h={{ xs: '48px', md: '59px' }}
              >
                {buttons.text}
              </Button>
            </Link>
          )}
        </Flex>
      </Flex>
    </SectionContainer>
  </Flex>
);

export default Slide;
