import React from 'react';
import { Box, Button, Flex, Link, Text } from '@chakra-ui/react';
import HeroSectionSlider from '@/containers/landingPage/HeroSectionSlider';
import Calendar from './Calendar';
import FeatureInfo from './featureSection/FeatureInfo';
import FeatureMap from './featureSection/FeatureMap';
import HomeDemoTestimonial from './HomeDemoTestimonial';
import MembersTabs from './MembersTabs';
import PartnerEvents from './PartnerEvents';

const slides = [
  {
    id: 1,
    title: 'IVCA Conclave 2025',
    desc: '11th - 12th Feb 2025 | Trident, Mumbai',
    showBtn: false,
    buttons: {
      text: 'Learn More',
      url: 'https://www.ivca.in/greenReturns-2024',
    },
    banner: {
      lg: 'https://static.levocdn.com/WGLBUT82/IVCAConclavebanner13-12WebsiteBanner-IVCAConclave1-4JOFlpJmdB4X.jpg',
    },
    contactInfo: (
      <Box
        w={{ xs: '100%', md: '60%' }}
        mt={{ xs: '10px', md: '0px' }}
        mb={{ xs: '-30px', md: '20px' }}
      >
        <Text
          color="white"
          fontSize={{ xs: '16px', md: '20px' }}
          fontWeight="bold"
        >
          For more information and partnership opportunities, write to us at
          <Link
            fontWeight={600}
            href="mailto:Aakriti@ivca.in"
            color="primary.500"
            px="6px"
          >
            Aakriti@ivca.in
          </Link>
          and
          {/* <Box as="br" display={{ xs: 'block', md: 'none' }} /> */}
          <Link
            fontWeight={600}
            href="mailto:Shreya@ivca.in"
            color="primary.500"
            px="6px"
          >
            Shreya@ivca.in
          </Link>
        </Text>
        <Flex mt={{ xs: '12px', md: '18px' }} gap={{ xs: '4px', md: '12px' }}>
          <Link
            href="https://ivcaconclave.com/"
            cursor="pointer"
            aria-label="btn"
            target="_blank"
          >
            <Button
              borderRadius="none"
              fontSize={{ xs: '12px', md: '18px' }}
              fontWeight={700}
              bgColor="white"
            >
              Explore 2024 Highlights
            </Button>
          </Link>
          <Link
            href="https://space.levo.so/WGLBUT82/IVCA-RipeMagazine2024WithADScompressed1-Bfj3oGUKhmnh.pdf"
            cursor="pointer"
            aria-label="btn"
            target="_blank"
          >
            <Button
              fontSize={{ xs: '12px', md: '18px' }}
              borderRadius="none"
              fontWeight={700}
              bgColor="white"
            >
              RIPE Magazine 2024
            </Button>
          </Link>
        </Flex>
      </Box>
    ),
  },
  {
    id: 2,
    title: 'Welcome to IVCA',
    desc: 'Indian Venture and Alternate Capital Association (IVCA) is a not-for-profit, apex industry body promoting the alternate capital industry and fostering a vibrant investing ecosystem in India.',
    showBtn: true,
    buttons: { text: 'About Us', url: '/about-us' },
    banner: { lg: '/webp/homeSlideOne.webp' },
  },
  {
    id: 3,
    title: 'The Indian Venture IPO and Exit Strategies Forum 2024',
    desc: '14th November 2024 | Mumbai',
    showBtn: true,
    buttons: {
      text: 'Learn More',
      url: 'https://www.ivca.in/indian-venture-ipo-and-exit-strategies-forum',
    },
    banner: {
      lg: 'https://space.levo.so/WGLBUT82/IVCAIPObanner-OpenFile-tryGJ0C6PfS9.jpg',
    },
  },
  {
    id: 4,
    title: 'SEBI AI Simplification',
    desc: 'SEBI AI Simplification of Requirements',
    showBtn: true,
    buttons: { text: 'Read More', url: '/sebiupdates/accredited-investor' },
    banner: { lg: 'https://space.levo.so/WGLBUT82/7130124319659207262.png' },
  },
  {
    id: 5,
    title: 'Regulatory Reporting - SEBI',
    desc: 'Regulatory Reporting by AIFs - Circular Issued by SEBI',
    showBtn: true,
    buttons: {
      text: 'Read More',
      url: '/sebiupdates/sebi-quarterly-reporting-by-aifs',
    },
    banner: { lg: '/webp/citySkyline.webp' },
  },
];

const HomePage: React.FunctionComponent = () => (
  <>
    <HeroSectionSlider slides={slides} />
    <FeatureInfo />
    <FeatureMap />
    <Calendar />
    <PartnerEvents />
    <MembersTabs />
    <HomeDemoTestimonial />
  </>
);

export default HomePage;
