import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

const features = [
  {
    id: 1,
    featureIcon: '/svg/educate.svg',
    link: '/events',
    title: 'Ecosystem Building (E)',
    desc: 'Drive industry enablement through partnerships with prominent educational and industry institutions. Organize high-impact webinars, panel discussions, courses, and roundtable conferences on pivotal industry topics.',
  },
  {
    id: 2,
    featureIcon: '/svg/advocacy.svg',
    link: '/resources',
    title: 'Advocacy (A)',
    desc: 'Engage directly with policymakers and financial regulators to shape policies critical to the alternative investment ecosystem. Foster collaborative efforts to promote a unified approach that supports industry growth and development.',
  },
  {
    id: 3,
    featureIcon: '/svg/research.svg',
    link: '/resources/recent-reports',
    title: 'Research (R)',
    desc: 'Source and curate comprehensive research initiatives in collaboration with industry partners and associations. Produce insightful industry reports and publications aimed at providing actionable intelligence to stakeholders and enabling informed decision-making.',
  },
  {
    id: 4,
    featureIcon: '/svg/network.svg',
    link: '/events',
    title: 'Network(N)',
    desc: 'Facilitate exclusive networking opportunities for industry stakeholders, including LP-GP Meets and deal flow discussions, to foster collaboration and knowledge exchange among key players.',
  },
];

const FeatureInfo: React.FunctionComponent = () => (
  <Box
    transform="translateY(-64px)"
    zIndex="30"
    bg="white"
    mx={{ xs: '20px', md: 'auto' }}
    maxW="1306px"
    boxShadow="20px 20px 124px rgba(0, 0, 0, 0.2)"
    pt={{ xs: '18px', md: '28px', lg: '30px' }}
    pb={{ xs: '25px', md: '30px', lg: '54px' }}
    px={{ xs: '16px', sm: '20px', md: '30px', lg: '35px', xl: '43px' }}
  >
    <Heading
      textAlign="center"
      as="h5"
      variant="calendarTitle"
      fontSize={{ xs: '22px', md: '25px' }}
      color="text.darkHead"
      lineHeight="1.6"
      mb={{ xs: '1rem', md: '2rem' }}
    >
      What we do
    </Heading>
    <SimpleGrid
      columns={{ xs: 1, md: 2 }}
      minH="239px"
      h="100%"
      rowGap={{ xs: '30px', sm: '40px' }}
    >
      {features.map((feature, index) => (
        <Flex
          as="article"
          key={feature.id}
          pl={{ md: index % 2 !== 0 ? '16px' : 0 }}
          borderRightWidth="1px"
          borderRightColor="bg.veryLightGrey"
          borderRightStyle={{
            xs: 'none',
            md: index % 2 !== 0 ? 'none' : 'solid',
          }}
        >
          <Box mr="12px" boxSize="60px" flexShrink="0">
            <Image
              src={feature.featureIcon}
              alt="feature icon"
              width={60}
              height={60}
            />
          </Box>
          <Box>
            <Flex mb="7px" alignItems="center" columnGap="10px">
              <Heading as="h3" variant="featureTitle">
                {feature.title}
              </Heading>
              <Box flexShrink="0" boxSize="23px">
                <Image
                  src="/svg/ext_icon.svg"
                  alt="external icon"
                  width={100}
                  height={100}
                />
              </Box>
            </Flex>
            <Text variant="featureText" color="text.mediumBlackAlpha" w="95%">
              {feature.desc}
            </Text>
            {/* <Link href={feature.link} passHref aria-label="Know More Button">
              <Button
                aria-label="Know More button"
                borderRadius="0px"
                mt="8px"
                variant="outline"
                color="text.textViolet"
                px={{ xs: '8px', md: '16px' }}
                fontSize={{ xs: '14px', md: '16px' }}
              >
                Know More
              </Button>
            </Link> */}
          </Box>
        </Flex>
      ))}
    </SimpleGrid>
  </Box>
);

export default FeatureInfo;
